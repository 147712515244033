<template>
  <div>
    <!-- This example requires Tailwind CSS v2.0+ -->
    <div class="relative bg-white overflow-hidden">
      <div class="max-w-7xl mx-auto">
        <div
          class="md:relative md:z-10 pb-8 bg-white sm:pb-16 md:pb-10 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32"
        >
          <svg
            class="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
            fill="currentColor"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            aria-hidden="true"
          >
            <polygon points="50,0 100,0 50,100 0,100" />
          </svg>

          <div class="relative pt-6 px-4 sm:px-6 lg:px-8"></div>

          <div id="home" class="font-bold text-left md:pb-3 md:pt-20 px-4">
            <div class="md:text-4xl text-3xl pb-3">
              Hey! My name is
            </div>
            <div class="md:text-8xl text-6xl pb-3">
              Kyle George
            </div>
            <vue-typed-js
              :strings="[
                'Mechatronic Engineer',
                'DevOps Engineer',
                'Founder',
                'Entrepreneur',
                'Developer',
                'Product Manager'
              ]"
              :typeSpeed="80"
              :backSpeed="80"
              :loop="true"
            >
              <div class="md:text-4xl text-2xl text-left">
                I'm a <span class="typing md:text-4xl text-2xl"></span>
              </div>
            </vue-typed-js>
          </div>
        </div>
      </div>
      <div
        class="lg:absolute hidden md:hidden lg:block lg:inset-y-0 lg:right-0 lg:w-1/2"
      >
        <img
          class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
          src="https://source.unsplash.com/2EVJTOrmZZs"
          alt=""
        />
      </div>
    </div>
    <div
      class="text-left md:text-center px-4 font-bold text-4xl pt-5 pb-5 lg:pb-16 lg:pt-20"
    >
      <span class="md:border-b-4 border-black">
        about me
      </span>
    </div>
    <div class="grid lg:grid-cols-4 md:grid-cols-1">
      <div class="col-span-1 md:block hidden md:py-5">
        <img
          src="@/assets/pre.png"
          alt="Logo"
          class="mx-auto h-56 border-gray-100 border-8"
        />
        <div class="text-center ">Pre Covid</div>
      </div>
      <div class="md:col-span-2 col md:py-5">
        <div class="px-4  text-left md:text-center">
          I am a mechatronics engineer with a passion to create useful products.
          I am more interested in <strong>why</strong> we build products, rather
          than <strong>how</strong> we do it.
        </div>
        <div class="pt-2 px-4 text-left md:text-center">
          I enjoy rapidly building <strong>functional prototypes</strong> to
          test my hypothesis about the problem a product aims to solve. I
          believe the best way to learn what the end user needs is to get
          something in there hands as soon as possible then identify what it's
          missing and what needs improvement by working closely with the end
          user.
        </div>
      </div>

      <div class="col-span-1 md:block hidden md:py-5">
        <img
          src="@/assets/post.png"
          alt="Logo"
          class="mx-auto h-56 border-gray-100 border-8"
        />
        <div class="text-center">Post Covid</div>
      </div>
    </div>

    <div
      class="text-4xl md:text-center text-left px-4 font-bold pt-5 pb-5 lg:pb-16 lg:pt-20"
    >
      <span class="md:border-b-4 border-black">
        my process
      </span>
    </div>
    <div
      class="md:grid xl:grid-cols-6 lg:grid-cols-3 md:grid-cols-2 hidden md:block"
    >
      <div class="col-span-1 mx-3">
        <div class="text-xl font-bold  md:pt-2">research</div>
        <svg
          class="w-auto h-20 mx-auto my-3"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
          ></path>
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
          ></path>
        </svg>
        <div>
          Validate the idea by researching the market, customer, problem and
          competitors
        </div>
      </div>
      <div class="col-span-1  mx-3">
        <div class="text-xl font-bold  md:pt-2">ideate</div>
        <div>
          <svg
            class="w-auto h-20 mx-auto my-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
            ></path>
          </svg>
        </div>
        <div>
          Identify the one core value (solution) the product would offer.
        </div>
      </div>
      <div class="col-span-1 mx-3">
        <div class="text-xl font-bold  md:pt-2">build</div>
        <div>
          <svg
            class="w-auto h-20 mx-auto my-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
            ></path>
          </svg>
        </div>
        <div>
          Build a functional prototype and launch beta
        </div>
      </div>
      <div class="col-span-1 mx-3">
        <div class="text-xl font-bold md:pt-2">test</div>
        <div>
          <svg
            class="w-auto h-20 mx-auto my-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
            ></path>
          </svg>
        </div>
        <div>
          Observe and test hypothesis by working with customers and monitoring
          traction
        </div>
      </div>
      <div class="col-span-1  mx-3">
        <div class="text-xl font-bold  md:pt-2">plan</div>
        <div>
          <svg
            class="w-auto h-20 mx-auto my-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
            ></path>
          </svg>
        </div>
        <div>
          Identify potential roadblocks, or missing value and features
        </div>
      </div>
      <div class="col-span-1 mx-3">
        <div class="text-xl font-bold  md:pt-2">refine</div>
        <div>
          <svg
            class="w-auto h-20 mx-auto my-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
            ></path>
          </svg>
        </div>
        <div>
          Iterate prototype, test, plan, repeat.
        </div>
      </div>
    </div>
    <div class="md:hidden">
      <carousel
        :autoplay="true"
        :speed="1"
        :perPage="1"
        :loop="true"
        :easing="ease"
      >
        <slide>
          <div class="text-xl font-bold">research</div>
          <svg
            class="w-auto h-20 mx-auto my-3"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            ></path>
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
            ></path>
          </svg>
          <div class="mx-3">
            Validate the idea by researching the market, customer, problem and
            competitors
          </div>
        </slide>
        <slide>
          <div class="text-xl font-bold">ideate</div>
          <div>
            <svg
              class="w-auto h-20 mx-auto my-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9.663 17h4.673M12 3v1m6.364 1.636l-.707.707M21 12h-1M4 12H3m3.343-5.657l-.707-.707m2.828 9.9a5 5 0 117.072 0l-.548.547A3.374 3.374 0 0014 18.469V19a2 2 0 11-4 0v-.531c0-.895-.356-1.754-.988-2.386l-.548-.547z"
              ></path>
            </svg>
            <div class="mx-3">
              Identify the one core value (solution) the product will offer.
            </div>
          </div>
        </slide>
        <slide>
          <div class="text-xl font-bold">build</div>
          <div>
            <svg
              class="w-auto h-20 mx-auto my-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
              ></path>
            </svg>
          </div>
          <div class="mx-3">
            Build a functional prototype and launch beta
          </div>
        </slide>
        <slide>
          <div class="text-xl font-bold">test</div>
          <div>
            <svg
              class="w-auto h-20 mx-auto my-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19.428 15.428a2 2 0 00-1.022-.547l-2.387-.477a6 6 0 00-3.86.517l-.318.158a6 6 0 01-3.86.517L6.05 15.21a2 2 0 00-1.806.547M8 4h8l-1 1v5.172a2 2 0 00.586 1.414l5 5c1.26 1.26.367 3.414-1.415 3.414H4.828c-1.782 0-2.674-2.154-1.414-3.414l5-5A2 2 0 009 10.172V5L8 4z"
              ></path>
            </svg>
          </div>
          <div class="mx-3">
            Observe and test hypothesis by working with customers and monitoring
            traction
          </div>
        </slide>
        <slide>
          <div class="text-xl font-bold">plan</div>
          <div>
            <svg
              class="w-auto h-20 mx-auto my-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
              ></path>
            </svg>
          </div>
          <div class="mx-3">
            Identify potential roadblocks, or missing value and features
          </div>
        </slide>
        <slide>
          <div class="text-xl font-bold">refine</div>
          <div>
            <svg
              class="w-auto h-20 mx-auto my-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              ></path>
            </svg>
          </div>
          <div class="mx-3">
            Iterate prototype, test, plan, repeat.
          </div>
        </slide>
      </carousel>
    </div>

    <div
      class="text-4xl md:text-center text-left px-4 font-bold pt-5 pb-5 lg:pb-16 lg:pt-20"
    >
      <span class="md:border-b-4 border-black">
        my experiences
      </span>
    </div>

    <div class="bg-gray-100 grid md:grid-cols-5 grid-cols-3 pt-5">
      <div class="col-span-1">
        <img
          src="@/assets/henlen.png"
          alt="Logo"
          class="mx-auto md:h-32 h-28 bg-gray-100 p-4"
        />
      </div>
      <div class="col-span-1">
        <img
          src="@/assets/uoit.png"
          alt="Logo"
          class="mx-auto md:h-32 h-28 bg-gray-100 p-4"
        />
      </div>
      <div class="col-span-1">
        <img
          src="@/assets/mxco.png"
          alt="Logo"
          class="mx-auto md:h-32 h-28 bg-gray-100 p-4"
        />
      </div>
      <div class="col-span-1">
        <img
          src="@/assets/enactus.png"
          alt="Logo"
          class="mx-auto md:h-32 h-28 bg-gray-100 p-4"
        />
      </div>
      <div class="col-span-1">
        <img
          src="@/assets/restock.png"
          alt="Logo"
          class="mx-auto md:h-32 h-28  bg-gray-100 p-4"
        />
      </div>
      <div class="md:hidden col-span-1">
        <div class="font-bold pt-10">so far...</div>
      </div>
    </div>
    <!-- <router-link to="/experience" class="btn mt-5 mb-5">explore</router-link> -->
  </div>
</template>
<script>
import Vue from "vue";
import VueTypedJs from "vue-typed-js";
Vue.use(VueTypedJs);
export default {};
</script>
